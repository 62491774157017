import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import { store } from '@/app/store';
import Axios from 'axios';
import AdminNavbar from '@/app/components/admin-navbar/AdminNavbar.vue';
import ConversationsTable from '@/app/components/conversations-management/conversations-table/ConversationsTable.vue';
import Vue from 'vue';
import * as moment from 'moment';
import NavBar from '@/app/components/shared/nav-bar/NavBar.vue';

@Component({
  name: 'conversations-management',
  components: {
    AdminNavbar,
    ConversationsTable,
    NavBar,
  },
})
export default class ConversationsManagement extends VueWizard {
  public projects: any[] = [];
  public EventBus: Vue = new Vue();
  public agents: any[] = [];
  public loading = false;
  public notExistingValue = '';
  public notRegisteredValue = '';

  created() {
    this.retrieveInfo();
  }

  getAgentUsername(id: string) {
    let username = 'No existe';
    this.agents.forEach(agent => {
      if (agent.agent.agent === id) {
        username = agent.username;
      }
    });
    return username;
  }

  getProjectName(id: string) {
    let name = 'Sin nombre';
    this.projects.forEach(project => {
      if (project._id === id) {
        name = project.name;
      }
    });
    return name;
  }

  async retrieveInfo() {
    this.loading = true;
    await this.getProjects();
    await this.getAgents();
    this.loading = false;
  }

  async asyncForEach(array: any, callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  async getProjects() {
    let url = `${BASE_URL_MANAGER}/project/company/${
      store.state.user!.user.company!._id
    }`;

    await Axios.get(url)
      .then(res => {
        this.projects = res.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async getAgents() {
    return new Promise<void>(async resolve => {
      this.agents = [];
      let url = `${BASE_URL_MANAGER}/user/all/${
        store.state.user!.user.company!._id
      }`;

      const res = await Axios.get(url);

      res.data.users.forEach((user: any) => {
        if (user.role === 'AGENT') {
          const localUser: any = {
            username: user.username,
            _id: user._id,
            role: user.role,
            name: user.name,
          };
          this.agents.push(localUser);
        }
      });

      await this.asyncForEach(this.agents, async (user: any) => {
        url = `${BASE_URL_MANAGER}/user/${user._id}/agent`;
        const res = await Axios.get(url);
        user.agent = {
          agent: res.data.agent._id,
          projects: res.data.agent.projects,
          subscriptions: res.data.agent.subscriptions,
        };
      });

      resolve();
    });
  }
}
